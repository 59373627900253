import React from "react";
import PropTypes from "prop-types";
import Image from "../Global/Image";
import SmartTag from "../../utils/TagHandler";

// ====

const Ratings = (props) => {
  const { ratings, sm, md, lg } = props;

  return (
    <>
      {ratings && (
        <ul className="list-unstyled m-0">
          {ratings.map((item) => {
            return (
              <>
                <li className="my-4">
                  {item.region && (
                    <h6 className="text-bold text-uppercase mb-2">
                      {SmartTag(item.region)}
                    </h6>
                  )}

                  <Image
                    src={item.icon?.content?.image?.filename}
                    alt={item.icon?.content?.title}
                    sm={sm}
                    md={md}
                    lg={lg}
                    className="mb-2"
                  />
                  {item.descriptor && (
                    <p className="small m-0">{item.descriptor}</p>
                  )}
                </li>
              </>
            );
          })}
        </ul>
      )}
    </>
  );

  return (
    <>
      {ratings && (
        <ul className="list-unstyled list-inline m-0 brand-ratings">
          {ratings.map((item) => {
            return (
              <>
                <li className="list-inline-item mx-1 brand-rating">
                  <Image
                    src={item.content?.image?.filename}
                    alt={item.content?.title}
                    sm={sm}
                    md={md}
                    lg={lg}
                    className="brand-rating-image"
                  />
                </li>
              </>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Ratings;

// ====

Ratings.propTypes = {
  ratings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
};
Ratings.defaultProps = {
  ratings: null,
  sm: "0x50",
  md: "0x50",
  lg: "0x50",
};
